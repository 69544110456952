import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnetApi';

export const get_user = (u) => {
    var url = u.page ? u.page : `${api}/users`;
    return axiosConfig.get(`${url}/${u.id}`, {
        params: {
            action: u.action,
            search: u.search
        },
    }).then(response => response);
}