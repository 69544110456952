import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    general: {
        logonav: {
            id: '',
            value: '',
        },
        homeimg: {
            id: '',
            value: ''
        },
        icon: {
            id: '',
            value: ''
        }
    },
    status: false
}

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        SetGeneral: (state, action) => {
            state.general = JSON.parse(action.payload.general)
            state.status = action.payload.status
        }
    }
})

export const { SetGeneral } = generalSlice.actions

export default generalSlice.reducer
