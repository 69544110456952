import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    auth: {
        token: '',
        user: {},
        loggedin: false
    },
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        SetLogin: (state, action) => {
            state.auth.token = action.payload.access_token
            state.auth.user = action.payload.user
            state.auth.loggedin = action.payload.loggedin
        },
        BalanceUser: (state, action) => {
            if (action.payload !== state.auth.user) {
                let balance = action.payload.balance;
                state.auth.user = {
                    ...state.auth.user,
                    balance
                }
            }
        },
        DataUser: (state, action) => {
            state.auth.user = action.payload.user
        },
        SetOut: (state, action) => {
            state.auth.token = ''
            state.auth.user = {}
            state.auth.loggedin = action.payload.loggedout
        },
    },
})

export const { SetLogin, DataUser, SetOut, BalanceUser } = authSlice.actions

export default authSlice.reducer