import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllTickets } from '../../../store/storeReducers/TicketsReducer';
import { OrderStatus } from '../../../components/ImagenStatus';
import moment from 'moment';
import Paginator from '../../../components/Paginator';
import { useOutletContext } from 'react-router-dom';
import { get_tickets } from '../../../functions/BillsFunction';
import { update_Spin } from '../../../store/storeReducers/LoadingReducer';
import LanguageContext from '../../../context/LanguageContext';

const BillsUser = () => {

    const { texts } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const param = useOutletContext();
    const [load, setLoad] = useState(false);
    const spin = useSelector((state) => state.loading.loading.spin);
    const tickets = useSelector((state) => state.tickets.tickets);

    useEffect(() => {
        getTickets();
        dispatch(update_Spin(true));
    }, [param.count])

    const getTickets = async (value) => {
        setLoad(true);
        try {
            const ticket = {
                action: 'tickets',
                search: value && value.search ? value.search : '',
                per_page: value && value.per_page ? value.per_page : '',
                page: value && value.page ? value.page : '',
                status: value && value.status ? value.status : '',
            }
            const res = await get_tickets(ticket);
            dispatch(AllTickets(res.data.tickets));
        } catch (error) {
        }
        setLoad(false);
        dispatch(update_Spin(false));
    }

    const Status = [
        { name: texts.AllStatus, state: '' },
        { name: texts.Success, state: '4' },
        { name: texts.Rejects, state: '3' }
    ]

    return (
        <div className='profile-content mg-bottom_100'>
            {
                !spin &&
                <div className='container'>
                    <div className='mg-left_auto bg-white mg-right_auto mg-bottom_100 mg-top_20  bd-radius_10 box-shadow_1 pd-10 '>
                        <div className='tx-center'>
                            <span className="ft-size_25">{texts.Bills}</span>
                        </div>
                        <div className="justify-content-center align-items-center table-responsive">
                            <table className='mg-left_auto mg-right_auto default_dg_table mg-bottom_20'>
                                <thead >
                                    <tr >
                                        <th className="default_dg_td tx-left default_dg_a_header col-md-1">#</th>
                                        <th className=" tx-center default_dg_a_header ">
                                            <select
                                                className="wd-100p bd-radius_10 outline-none ft-size_15 bd-solid_dadce0"
                                                onChange={(e) => getTickets({ status: e.target.value })}
                                            >
                                                {
                                                    Status.map((group, index) => {
                                                        return (
                                                            <option key={index} value={group.state}>{group.name}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </th>
                                        <th className="default_dg_td tx-center default_dg_a_header ">{texts.Amount}</th>
                                        <th className="default_dg_td tx-center default_dg_a_header">{texts.Reason}</th>
                                        <th className="default_dg_td tx-center default_dg_a_header">{texts.CreatedDay}</th>
                                    </tr>
                                </thead>
                                {
                                    tickets.data.map((t, k) => {
                                        const status = OrderStatus({ status: t.status, text: texts });
                                        return (
                                            <tbody key={k}>
                                                <tr className="dg_tr cursor-pointer ft-family_monospace">
                                                    <td className="default_dg_td tx-left dg_nowrap bd-lright_solid1_f1 white_space">#{k + 1}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-lright_solid1_f1 white_space">{status}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-lright_solid1_f1 white_space">{t.amount}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-lright_solid1_f1 white_space">{t.reason}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-lright_solid1_f1 white_space">{moment(t.created_at).format('DD-MM-YY HH:mm')}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })
                                }
                            </table>
                            <Paginator
                                data={tickets}
                                action={(t) => getTickets(t)}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default BillsUser