import React, { useContext, useEffect, useRef, useState } from 'react';
import { PrivateServer } from '../../../store/storeReducers/GroupsReducer';
import { BalanceUser } from '../../../store/storeReducers/AuthReducer';
import { useSelector, useDispatch } from "react-redux";
import { get_service } from '../../../functions/ServiceFunction';
import { get_groups } from '../../../functions/GroupFunction';
import { new_order } from '../../../functions/OrderFunction';
import { BsToggle2Off, BsToggle2On } from 'react-icons/bs'
import ModalNewOrder from '../../../components/modals/ModalNewOrder';
import { update_Spin } from '../../../store/storeReducers/LoadingReducer';
import LanguageContext from '../../../context/LanguageContext';


const ServerServices = () => {
    const { texts } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const allGroups = useSelector((state) => state.groups.groups.private.groupServe);
    const user = useSelector((state) => state.auth.auth.user);
    const spin = useSelector((state) => state.loading.loading.spin);
    const [visible, setVisible] = useState(false);
    const [sendRequest, setRequest] = useState('');
    const [custom, setCustom] = useState([]);
    const [service, setService] = useState('');
    const [imeis, setImeis] = useState([]);
    const [comments, setComments] = useState('');
    const [lists, setList] = useState('');
    const [imei, setImei] = useState([]);
    const [qnt, setQnt] = useState('');
    const [price, setPrice] = useState('');
    const [error, setErr] = useState({ err: [] });
    const [options, setOpt] = useState({
        menu: false,
        burk: false,
        loading: false
    });

    useEffect(() => {
        if (allGroups[0] == null) {
            getGroups();
            dispatch(update_Spin(true));
        }
    }, [visible]);

    const getGroups = async (value) => {
        try {
            const group = {
                type: 'SERVER',
                action: 'groups',
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_groups(group);
            dispatch(PrivateServer(res.data.groups));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Spin(false));
    }

    const _onService = async (id) => {
        setList('');
        setService('');
        setComments('');
        setCustom([]);
        setQnt('');
        if (!id) {
            return setService();
        }
        try {
            const res = await get_service({ id, action: 'server' });
            if (res.data.service) {
                setService(res.data.service);
                setPrice(res.data.service.serviceprice.price);
                if (res.data.service.custom) {
                    setCustom(res.data.service.custom);
                }
            }
        } catch (error) {
            alert(error.response.data)
        }
    }

    const _newImei = async (value) => {
        setOpt({ ...options, loading: true });
        const join = [...imeis, ...imei]
        setErr('');
        try {
            const order = {
                serviceimei_id: service.id,
                data: join,
                custom: custom,
                comments: comments,
                qnt: qnt
            }
            const res = await new_order(order);
            setRequest(res.data);
            _open('s');
            dispatch(BalanceUser(res.data.user));
        } catch (err) {
            if (err.response.data && err.response.data.err) {
                setErr(err.response.data.err);
            }
        }
        setOpt({ ...options, loading: false });
    }
    const handleChange = (e) => {
        if (e && e.target.name === 'comments') {
            return setComments(e.target.value);
        }
        setImeis(
            e.target.value.split('\n')
        );
    }

    const handleQnt = (e) => {
        setQnt(e.target.value);
        setPrice(service.serviceprice.price * e.target.value);
    }

    const _open = (s) => {
        if (s) {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
            setVisible(true);
        } else {
            setVisible(!visible);
            document.getElementsByTagName("body")[0].style.overflow = "auto";
        }
    }

    const toggleHandler = (e) => {
        let newArray = [...custom];
        newArray[e.key] =
            { ...e.value, value: e.e.target.value, key: e.key }
        setCustom(newArray);
    };

    const handleList = () => {
        if (lists) {
            setList('')
        } else {
            setList('dropdown-list')
        }
    }

    return (
        <div className='profile-content'>
            {
                !spin &&
                <>
                    <ModalNewOrder
                        isVisible={visible}
                        orders={sendRequest}
                        close={() => _open()}
                        navi={'/historial-server'}
                    />
                    <div className='container' >
                        <div className='bg-white mg-left_auto mg-right_auto mg-top_20 default_dg_table bd-radius_10 box-shadow_1 default_dg_td '>
                            <div className="pst-relative">
                                <div className={`${lists} dropdown-simple select-none`}>
                                    <a onClick={() => handleList()}>
                                        <span>
                                            {service ? service.name + ` - - $${(service && service.serviceprice && service.serviceprice.price).toFixed(2)}${service.coin}` : texts.SelectService}
                                        </span>
                                    </a>
                                    <div className='dropdown'>
                                        <div className='search-list'>
                                            <input
                                                onChange={(e) => getGroups({ search: e.target.value })}
                                                autoComplete='new-password'
                                                className='search-input'
                                            />
                                        </div>
                                        <ul className='results-dropdown'>
                                            {
                                                [{ id: '', name: '', serviceimei: [{ id: '', name: texts.SelectService }] }, ...allGroups].map((group, index) => {
                                                    return (
                                                        <li className='group-list' key={index} label={group.name}>
                                                            <span>
                                                                {group.name}
                                                            </span>
                                                            {
                                                                group.serviceimei &&
                                                                group.serviceimei.map((s, k) => {
                                                                    return (
                                                                        <ul className='pd-0' key={k} value={s.id} >
                                                                            {
                                                                                <li
                                                                                    value={s.id}
                                                                                    onClick={(e) => _onService(e.target.value)}
                                                                                    className='result-select'>
                                                                                    {s.name} {s.serviceprice && s.serviceprice.price && `- - $${(parseFloat(s.serviceprice.price).toFixed(2) || (0).toFixed(2))}`}
                                                                                </li>
                                                                            }
                                                                        </ul>
                                                                    )
                                                                })
                                                            }
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div >
                            {
                                service &&
                                <>
                                    <div className="row row-cols-xl-2">
                                        <div className='container'>
                                            <div className='bg-white mg-bottom_100 mg-top_20 default_dg_table bd-radius_10 box-shadow_1 default_dg_td'>
                                                <div className='pst-relative row align-items-center'>
                                                    {
                                                        service.qnt == true && service.qntoption == null &&
                                                        <div className='pd-right_40'>
                                                            <div className='ft-size_12 color-red'>
                                                                {error && error.qnt}
                                                            </div>
                                                            <span>Qnt:</span>
                                                            <input
                                                                value={qnt}
                                                                placeholder='Qnt:'
                                                                name='qnt'
                                                                onChange={(e) => handleQnt(e)}
                                                                className="mg-top_7 bg-f1f3f4 wd-100p bd-radius_10 pd-5_10 outline-none ft-size_15 bd-solid_dadce0"
                                                                type={'number'}
                                                            />
                                                            <span className='dp-block mg-bottom_10'>The range of the qnt sould be {service.minqnt} to {service.maxqnt}</span>
                                                        </div>
                                                    }
                                                    {
                                                        service.qntoption &&
                                                        <div>
                                                            <div className='ft-size_12 color-red'>
                                                                {error && error.qnt}
                                                            </div>
                                                            <span>Qnt:</span>
                                                            <select
                                                                name='qnt'
                                                                onChange={(e) => handleQnt(e)}
                                                                className="wd-100p mg-bottom_20 bd-radius_10 pd-5 outline-none ft-size_15 bd-solid_dadce0"
                                                            >
                                                                {
                                                                    service.qntoption.split(',').map((q, k) => {
                                                                        return (
                                                                            <option key={k}>
                                                                                {q}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    }
                                                    {
                                                        service.bulk == true &&
                                                        <div>
                                                            <div className='select-none mg-bottom_20 pd-5_10 on-opt bg-white dp-flex align-items-center'>
                                                                <span>bulk:</span>
                                                                {
                                                                    options.bulk ?
                                                                        <BsToggle2On onClick={() => setOpt({ ...options, bulk: false })} className='ft-size_20 cursor-pointer color-green' />
                                                                        :
                                                                        <BsToggle2Off onClick={() => setOpt({ ...options, bulk: true })} className='ft-size_20 cursor-pointer color-red' />
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    <div>
                                                        {
                                                            options.bulk &&
                                                            <textarea
                                                                placeholder='Bulk:'
                                                                className='tx-area mg-bottom_20 pd-5_10 bd-radius_10 pd-5 outline-none bd-solid_dadce0'
                                                                onChange={(e) => handleChange(e)}>
                                                            </textarea>
                                                        }
                                                    </div>
                                                    {
                                                        custom.map((sc, k) => {
                                                            return (
                                                                <div key={k} className='pd-right_40'>
                                                                    <div className='ft-size_12 color-red'>
                                                                        {error && error[sc.fieldname]}
                                                                    </div>
                                                                    <input
                                                                        onChange={(e) => toggleHandler({ e, key: k, value: sc })}
                                                                        name={sc.fieldname}
                                                                        type={sc.fieldtype}
                                                                        placeholder={sc.fieldname ? sc.fieldname : ''}
                                                                        className="bg-f1f3f4 wd-100p bd-radius_10 pd-5_10 outline-none ft-size_15 bd-solid_dadce0"
                                                                    />
                                                                    <span className='dp-block pd-5_10 ft-size_12'>
                                                                        {sc.description ? sc.description : ""}
                                                                    </span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div>
                                                        <textarea
                                                            name='comments'
                                                            className='tx-area comm mg-bottom_20 pd-5_10 bd-radius_10 pd-5 outline-none bd-solid_dadce0'
                                                            placeholder={`${texts.Comments}:`}
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="crt-order">
                                                        <button
                                                            disabled={options.loading}
                                                            onClick={() => _newImei()}
                                                            className='mg-top_20 bd-none bg-primary pd-5 bd-radius_10 cursor-pointer color-white'>
                                                            {
                                                                options.loading ?
                                                                    <div className='spin order' ></div>
                                                                    :
                                                                    <div>{texts.NewOrder}</div>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container'>
                                            <div className='bg-white color-black mg-bottom_100 mg-top_20 default_dg_table bd-radius_10 box-shadow_1 default_dg_td'>
                                                <div className='cont-head'>
                                                    <span className='ft-size_25'>${service && service.serviceprice && (parseFloat(price).toFixed(2) || (0).toFixed(2))}{service.coin}</span>
                                                    <span className='ft-size_13'>
                                                        {texts.AvailableBalance}: ${(parseFloat(user.balance).toFixed(2) || (0).toFixed(2))}</span>
                                                    {
                                                        service.time &&
                                                        <span className='ft-size_13'>{texts.DeliveryTime}: {service.time}</span>
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group ">
                                                        <div dangerouslySetInnerHTML={{ __html: service.description }} >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </>
            }
        </div >
    )
}

export default ServerServices