import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnetApi';

export const auth_prelogin = () => {
    var url = `${api}/prelogin`;
    return axiosConfig.post(`${url}`, {
    }).then(response => response);
}

export const sign_out = () => {
    var url = `${api}/Signoff`;
    return axiosConfig.get(`${url}`, {
    }).then(response => response);
}