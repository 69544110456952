import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnetApi';

export const get_tickets = (u) => {
    var url = u.page ? u.page : `${api}/tickets`;
    return axiosConfig.get(url, {
        params: {
            action: u.action,
            search: u.search,
            status: u.status,
            per_page: u.per_page
        },
    }).then(response => response);
}