import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdAddCircle } from 'react-icons/md';
import { IoAddCircleOutline } from 'react-icons/io5';
import { get_user } from '../../../functions/UserFunction';
import { get_banks } from '../../../functions/BankFunction';
import Modalbanks from '../../../components/modals/Modalbanks';
import Paginator from '../../../components/Paginator';
import { useOutletContext } from 'react-router-dom';
import { update_Spin } from '../../../store/storeReducers/LoadingReducer';
import LanguageContext from '../../../context/LanguageContext';


const DataUser = () => {

    const { texts } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.auth);
    const spin = useSelector((state) => state.loading.loading.spin);
    const param = useOutletContext();
    const [infoUser, setUser] = useState({});
    const [isVisible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    const [banks, setBanks] = useState([]);
    const [bank, setBank] = useState({});
    const [allOrders, setAll] = useState({});

    useEffect(() => {
        getUser();
        dispatch(update_Spin(true));
    }, [param.count]);

    const getUser = async () => {
        try {
            const newUser = {
                id: user.user.id,
                action: 'data-user',
            }
            const res = await get_user(newUser);
            setUser(res.data.user);
            setAll(res.data);
        } catch (error) {
        }
        dispatch(update_Spin(false));
    }

    const getBanks = async (value) => {
        setShow(true);
        try {
            const bank = {
                action: 'banks-active',
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_banks(bank);
            setBanks(res.data.banks);
        } catch (error) {
            alert(error.response.data);
        }
    }

    const _open = (b) => {
        if (b) {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
            setBank(b);
            setVisible(true);
        } else {
            setVisible(!isVisible);
            document.getElementsByTagName("body")[0].style.overflow = "auto";
        }
    }

    return (
        <div className='profile-content mg-bottom_100'>
            {
                !spin &&
                <>
                    <Modalbanks
                        isVisible={isVisible}
                        bank={bank}
                        onClose={() => _open()}
                    />
                    <div className='container'>
                        <div className='personal-data'>
                            <div className='card-client card-balance box-shadow_1'>
                                <span className='ft-size_28'>{texts.YourBalance}</span>
                                <span className='ft-size_50'> ${(parseFloat(infoUser.balance).toFixed(2) || (0).toFixed(2))}</span>
                                <div className='button-add'>
                                    <button onClick={() => getBanks()} title='+Add balance' className='add-fonds'>
                                        <span className='ft-size_15 mg-8'>{texts.AddFunds}</span>
                                        <MdAddCircle className='cursor-pointer' />
                                    </button>
                                </div>
                            </div>
                            {
                                show &&
                                <div className='card-client size-banks box-shadow_1 bg-white bd-radius_8'>
                                    <table className='mg-left_auto mg-right_auto default_dg_table'>
                                        <thead>
                                            <tr >
                                                <th className=" bd-left_solid1_f1 bd-lright_solid1_f1 tx-left default_dg_a_header">{texts.Name}</th>
                                                <th className=" bd-left_solid1_f1 bd-lright_solid1_f1 tx-left default_dg_a_header">{texts.NumAccount}</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {
                                                banks && banks.data &&
                                                banks.data.map((b, k) => {
                                                    return (
                                                        <tr onClick={() => _open(b)} key={k} className="dg_tr cursor-pointer ft-family_monospace">
                                                            <td className="default_dg_td  tx-left dg_nowrap  white_space ">{b.name}</td>
                                                            <td className="default_dg_td  tx-left dg_nowrap  white_space ">{b.num_account.substring(0, 7)}....</td>
                                                            <td className="default_dg_td  tx-left dg_nowrap  white_space "> <IoAddCircleOutline /> </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <Paginator
                                        data={banks}
                                        action={(b) => getBanks(b)}
                                    />
                                </div>
                            }
                            <div className="cards">
                                <div className='success ord-card box-shadow_1 bd-solid1_7ab620' >
                                    <div className='tx-center pd-top_20 pd-right_10'>
                                        <span className='color-black'>
                                            <span className='ft-size_20 dp-block'>{texts.Orders}</span>
                                            {allOrders.success} / {allOrders.all}
                                        </span>
                                    </div>
                                    <div className='status success'>
                                        {texts.Success}:
                                    </div>
                                    <div className='charge'>
                                        <span className='green rejects'></span>
                                    </div>
                                </div>
                                <div className='rejects ord-card box-shadow_1 bd-solid1_fd3a5a'>
                                    <div className='tx-center pd-top_20 pd-right_10'>
                                        <span className='color-black'>
                                            <span className='ft-size_20 dp-block'>{texts.Orders}</span>
                                            {allOrders.reject} / {allOrders.all}
                                        </span>
                                    </div>
                                    <div className='status rejects'>
                                        {texts.Rejects}:
                                    </div>
                                    <div className='charge'>
                                        <span className='red rejects'></span>
                                    </div>
                                </div>
                                <div className='proccess ord-card box-shadow_1 bd-solid1_4bafea'>
                                    <div className='tx-center pd-top_20 pd-right_10'>
                                        <span className='color-black'>
                                            <span className='ft-size_20 dp-block'>{texts.Orders}</span>
                                            {allOrders.proccess} / {allOrders.all}
                                        </span>
                                    </div>
                                    <div className='status proccess'>
                                        {texts.Proccess}:
                                    </div>
                                    <div className='charge'>
                                        <span className='blue rejects'></span>
                                    </div>
                                </div>
                                <div className='news ord-card box-shadow_1 bd-solid1_BEC5D4'>
                                    <div className='tx-center pd-top_20 pd-right_10'>
                                        <span className='color-black'>
                                            <span className='ft-size_20 dp-block'>{texts.Orders}</span>
                                            {allOrders.news} / {allOrders.all}
                                        </span>
                                    </div>
                                    <div className='status news'>
                                        {texts.News}:
                                    </div>
                                    <div className='charge'>
                                        <span className='orange rejects'></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default DataUser