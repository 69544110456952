import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import LanguageContext from '../../context/LanguageContext';

const ModalNewOrder = (props) => {
    const { texts } = useContext(LanguageContext);
    const navigate = useNavigate();
    const { isVisible, orders, close, navi } = props
    const _open = () => {
        document.getElementsByTagName("body")[0].style.overflow = "auto";
        navigate(navi);
    }

    return (
        <>
            {
                isVisible &&
                <div className="container">
                    <div className="ebcf_modal ">
                        <div className="wd-600 ebcf_modal-content-orders">
                            <div className='pd-10_0'>
                                <table className="pd-7 mg-left_auto mg-right_auto mg-bottom_20 default_dg_table bd-radius_10 box-shadow_1">
                                    <thead >
                                        <tr >
                                            <th className="default_dg_td tx-left default_dg_a_header col-md-4">{texts.Data}</th>
                                            <th className="default_dg_td tx-center default_dg_a_header">{texts.Reason}</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                            orders.succes.map((o, k) => {
                                                return (
                                                    <tr key={k} className={`ft-family_monospace`}>
                                                        <td className={`pd-left_7 pd-0 ords${o.status} default_dg_td tx-left dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space`}>{o.data}</td>
                                                        <td className={`pd-0 ords${o.status} default_dg_td tx-center dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space`}>{o.reason}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='mg-10'>
                                {
                                    orders.key &&
                                    <span>
                                        {texts.TotalOrdersSend}: {orders.key}
                                    </span>
                                }
                            </div>
                            <div className='table-add dp-flex justify-content-between'>
                                <button
                                    onClick={() => _open()}
                                    className='ft-size_12 bd-none bg-primary pd-8 bd-radius_10 cursor-pointer color-white'
                                >
                                    {texts.OrdersHistory}
                                </button>
                                <button
                                    onClick={close}
                                    className='ft-size_12 bd-none bg-primary pd-8 bd-radius_10 cursor-pointer color-white'
                                >
                                    {texts.StayPage}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default ModalNewOrder