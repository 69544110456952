import React, { useContext, useState } from 'react';
import { GrFormSearch } from 'react-icons/gr';
import LanguageContext from '../context/LanguageContext';


const Search = (props) => {
    const { texts } = useContext(LanguageContext);
    const [search, setSearch] = useState('')
    const { action, load, place } = props


    return (
        <div className='srch justify-content-center dp-flex align-items-center mg-top_20 mg-bottom_20 pst-relative' >
            <GrFormSearch className='bd-corners_left_10  bd-solid_dadce0 bd-right_none hg-23' />
            <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder={place}
                className='wd-50p ft-size_12 hg-23 bd-left_none bd-solid_dadce0 outline-none'
            />
            <button
                disabled={load}
                onClick={() => action({ search: search })}
                className='itm-center dp-flex select-none bg-primary  color-white bd-left_none bd-corners_right_10 cursor-pointer hg-23 bd-solid_dadce0 outline-none'
            >
                {
                    load ?
                        <div className='spin search'></div>
                        :
                        <div>{texts.Search}</div>
                }
            </button>
        </div>
    )
}

export default Search