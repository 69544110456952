import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { api } from '../../../api/ConnetApi';
import { DataUser } from '../../../store/storeReducers/AuthReducer';
import { countries } from '../../../constants/countries';
import { get_user } from '../../../functions/UserFunction';
import { useOutletContext } from 'react-router-dom';
import { update_Spin } from '../../../store/storeReducers/LoadingReducer';
import LanguageContext from '../../../context/LanguageContext';

const ProfileUser = () => {

    const { texts } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const param = useOutletContext();
    const u = useSelector((state) => state.auth.auth);
    const spin = useSelector((state) => state.loading.loading.spin);
    const [loading, setLoad] = useState(false);
    const [user, setUser] = useState({
        name: '',
        last_name: '',
        email: '',
        password: '',
        address: '',
        country: '',
        phone: '',
        isActive: '',
    });

    useEffect(() => {
        getUser();
        dispatch(update_Spin(true));
    }, [param.count]);

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const getUser = async () => {
        try {
            const res = await get_user({ id: u.user.id, action: 'info' });
            if (res.data.user) {
                setUser(res.data.user);
                dispatch(DataUser(res.data));
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Spin(false));
    }

    const updateUser = async () => {
        setLoad(true);
        await axios.put(`${api}/users/${u.user.id}`, user, {
            params: {
                action: 'info'
            },
            headers: {
                "Authorization": `Bearer ${u.token}`,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(res => {
                if (res.data.alert) {
                    alert(res.data.alert)
                }
            }).catch((err) => {
                if (err.response.data && err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            });
        setLoad(false);
    }



    return (
        <div className='profile-content'>
            {
                !spin &&
                <div className='container'>
                    <div className='mg-left_auto bg-white mg-right_auto default_dg_table mg-bottom_100 mg-top_20  bd-radius_10 box-shadow_1 default_dg_td '>
                        <div className='container'>
                            <div className="row justify-content-center align-items-center ">
                                <div className="col-10 col-lg-10 ">
                                    <div className=' mg-bottom_20 mg-top_20'>
                                        <span className="ft-size_25 ">{texts.BasicInfo}</span>
                                    </div>
                                    <form>
                                        <div className="row bd-bottom_solid_f1 pd-bottom_10 pd-top_10 bd-top_solid_f1">
                                            <div className="col">
                                                <div className="prfl">
                                                    <span className='ft-size_12 color-5f6368'>{texts.Name}: </span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <input
                                                    onChange={(e) => handleChange(e)}
                                                    defaultValue={user.name}
                                                    name='name'
                                                    className='ft-size_16 wd-100p bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                                    placeholder={texts.Name} />
                                            </div>
                                        </div>
                                        <div className="row bd-bottom_solid_f1 pd-bottom_10 pd-top_10 bd-top_solid_f1">
                                            <div className="col">
                                                <div className="prfl">
                                                    <span className='ft-size_12 color-5f6368'>{texts.LastName}: </span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <input
                                                    onChange={(e) => handleChange(e)}
                                                    defaultValue={user.last_name}
                                                    name='last_name'
                                                    className='ft-size_16  wd-100p bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                                    placeholder={texts.LastName} />
                                            </div>
                                        </div>
                                        <div className="row  bd-bottom_solid_f1 pd-bottom_10 pd-top_10 bd-top_solid_f1">
                                            <div className="col">
                                                <div className="prfl">
                                                    <span className='ft-size_12 color-5f6368'>{texts.Address}: </span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <input
                                                    onChange={(e) => handleChange(e)}
                                                    defaultValue={user.address}
                                                    name='address'
                                                    className='ft-size_16  wd-100p bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                                    placeholder={texts.Address} />
                                            </div>
                                        </div>
                                        <div className="row  bd-bottom_solid_f1 pd-bottom_10 pd-top_10 bd-top_solid_f1">
                                            <div className="col">
                                                <div className="prfl">
                                                    <span className=' ft-size_12 color-5f6368'>{texts.Country}: </span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <select
                                                    className=' ft-size_16 wd-100p bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                                    name='country'
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    {
                                                        [{ "name": user.country }, ...countries].map((p, k) => {
                                                            return (
                                                                <option key={k} value={p.name}>
                                                                    {p.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='container'>
                            <div className="row justify-content-center align-items-center ">
                                <div className="col-9 col-lg-10 ">
                                    <div className='mg-bottom_20 mg-top_20'>
                                        <span className="ft-size_25 ">{texts.PersonalInfo}</span>
                                    </div>
                                    <form>
                                        <div className="row  bd-bottom_solid_f1 pd-bottom_10 pd-top_10 bd-top_solid_f1">
                                            <div className="col">
                                                <div className="prfl">
                                                    <span className='ft-size_12 color-5f6368'>{texts.Email}: </span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <input
                                                    disabled
                                                    className='ft-size_16 ft-weight_bold wd-100p outline-none hg-36 pd-6_8 '
                                                    defaultValue={user.email}
                                                />
                                            </div>
                                        </div>
                                        <div className="row  bd-bottom_solid_f1 pd-bottom_10 pd-top_10 bd-top_solid_f1">
                                            <div className="col">
                                                <div className="prfl">
                                                    <span className='ft-size_12 color-5f6368'>{texts.PhoneNumber}: </span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <input
                                                    defaultValue={user.phone}
                                                    onChange={(e) => handleChange(e)}
                                                    name='phone'
                                                    autoComplete='new-password'
                                                    type={'tel'}
                                                    className='ft-size_16 wd-100p bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                                    placeholder={texts.PhoneNumber} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='container'>
                            <div className="row justify-content-center align-items-center ">
                                <div className="col-9 col-lg-10 ">
                                    <div className=' mg-bottom_20 mg-top_20'>
                                        <span className="ft-size_25 ">{texts.ExamplePassword}</span>
                                    </div>
                                    <form>
                                        <div className="row  bd-bottom_solid_f1 pd-bottom_10 pd-top_10 bd-top_solid_f1">
                                            <div className="col">
                                                <div className="prfl">
                                                    <span className='ft-size_12 color-5f6368'>{texts.ExamplePassword}: </span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <input
                                                    defaultValue={user.password}
                                                    onChange={(e) => handleChange(e)}
                                                    name='password'
                                                    autoComplete='new-password'
                                                    type={'password'}
                                                    className='ft-size_16 wd-100p bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                                    placeholder={texts.ExamplePassword} />
                                            </div>
                                        </div>
                                    </form>
                                    <button
                                        onClick={() => updateUser()}
                                        className='cursor-pointer bd-solid_dadce0 bg-primary color-white bd-radius_4 pd-5 mg-top_10  mg-bottom_10'>
                                        {
                                            loading ?
                                                <div className='spin order' ></div>
                                                :
                                                <div>{texts.Update}</div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProfileUser