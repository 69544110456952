import React, { useContext } from 'react';
import { MdAttachMoney } from "react-icons/md";
import { BsShieldCheck } from "react-icons/bs";
import { IoIosFlash } from "react-icons/io";
import { HiUserGroup } from "react-icons/hi";
import LanguageContext from '../../context/LanguageContext';


const Cardfirst = () => {

    const { texts } = useContext(LanguageContext);
    const domainUrl = document.domain;

    return (
        <div className='content-cards'>
            <div className='cards align-items-none' >
                <div className='bg-white box-shadow_1 tx-center bd-radius_10'>
                    <li className='bg-primary bd-corners_top_10 color-white list-none ft-size_50'>
                        <HiUserGroup />
                    </li>
                    <h3 className='color-black'>{texts.AboutUs}</h3>
                    <p className='pd-15 ft-size_13 color-black'>
                        {texts.InfoAbout}
                    </p>
                </div>
            </div>
            <div className='cards align-items-none' >
                <div className='bg-white box-shadow_1 tx-center bd-radius_10'>
                    <li className='bg-primary bd-corners_top_10 color-white list-none ft-size_50'>
                        <IoIosFlash />
                    </li>
                    <h3 className='color-black'>{texts.DeliveryTime}</h3>
                    <p className='pd-15 ft-size_13 color-black'>
                        {texts.InfoDelivery}
                    </p>
                </div>
            </div>
            <div className='cards align-items-none' >
                <div className='bg-white box-shadow_1 tx-center bd-radius_10'>
                    <li className='bg-primary bd-corners_top_10 color-white list-none ft-size_50 color-black'>
                        <MdAttachMoney />
                    </li>
                    <h3 className='color-black'>{texts.Warranty}</h3>
                    <p className='pd-15 ft-size_13'>
                        {texts.InfoWarranty}
                    </p>
                </div>
            </div>
            <div className='cards align-items-none' >
                <div className='bg-white box-shadow_1 tx-center bd-radius_10'>
                    <li className='bg-primary bd-corners_top_10 color-white list-none ft-size_50'>
                        <BsShieldCheck />
                    </li>
                    <h3 className='color-black'>{texts.Security}</h3>
                    <p className='pd-15 ft-size_13 color-black'>
                        {texts.InfoSecurity}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Cardfirst