export const en = {
    Login: 'Login',
    ExampleEmail: 'Username@example.com',
    ExamplePassword: 'Password',
    Sigin: 'Sig in',
    Register: 'Create your new account',
    FirstName: 'Name',
    LastName: 'Last name',
    InfoEmail: 'Require email (you can use letters, numbers and periods)',
    Address: 'Address',
    InfoAddress: 'The address is optional',
    SelectCountry: '-Select yout country-',
    InfoCountry: 'The country is optional',
    PhoneNumber: 'Phone number',
    InfoNumber: 'The phone number is optional',
    InfoPassword: 'Use a minimum of 6 or more characters',
    Confirm: 'Confirm',
    ButtonRegister: 'Register',
    ServicesFind: 'Find services through groups:',
    AllGroups: 'All groups',
    Time: 'Time',
    Price: 'Price',
    AboutUs: 'About Us',
    InfoAbout: 'We are a working group, dedicated to the services of automated pages, trying to meet the expectations of our customers and provide an excellent service.',
    DeliveryTime: 'Delivery Time',
    InfoDelivery: 'The delivery times we offer are the fastest in the market , delivered quickly and efficiently.',
    Warranty: 'Warranty Services',
    InfoWarranty: 'When contracting any of the services we offer, we guarantee a solid refund as long as the service fails and the terms of the guarantee are met.',
    Security: 'Security',
    InfoSecurity: 'We provide security and protection for the user, any interaction with our website, the information acquired is stored securely and encrypted.',
    Menu: 'Menu',
    ConstactUs: 'Contact Us',
    Contacts: 'Contacts',
    TermsCondition: 'Terms & Condition',
    PrivacyPolicy: 'Privacy Policy',
    YourEmail: 'Your email address',
    YourMessage: 'Your message',
    Send: 'Send',
    Orders: 'Orders',
    YourBalance: 'Your balance:',
    AddFunds: '+Add funds',
    Success: 'Success',
    Rejects: 'Rejects',
    Reject: 'Reject',
    Proccess: 'Proccess',
    Waiting: 'Waiting',
    News: 'News',
    Name: 'Name',
    NumAccount: 'Num account',
    BasicInfo: 'Basic information',
    Country: 'Contry',
    Email: 'Email',
    PersonalInfo: 'Personal information',
    Update: 'Update',
    Bills: 'Bills',
    Amount: 'Amount',
    Reason: 'Reason',
    CreatedDay: 'Created day',
    AllStatus: 'All status',
    SelectService: '--Select The Service--',
    AllOrdersImei: 'All orders imei',
    AllOrdersServer: 'All orders server',
    Service: 'Service',
    Imei: 'Imei',
    Response: 'Response',
    Search: 'Search',
    SearchByImei: 'Search by imei',
    Reference: 'Reference',
    Comments: 'Comments',
    Close: 'Close',
    CreatedAt: 'Created at',
    ResponsedAt: 'Responsed at',
    Data: 'Data',
    Reason: 'Reason',
    OrdersHistory: 'Orders History',
    StayPage: 'Stay on the page',
    TotalOrdersSend: 'Total orders send',
    Language: 'Language',
    NewOrder: 'New order',
    AvailableBalance: 'Available balance',
    Balance: 'Balance',
    LogOut: 'Log out',
    Home: 'Home',
    Register: 'Register',
    Login: 'Login',
    ClientData: 'Client data',
    ServicesPrices: 'Services and prices',
    OrderHistory: 'Order history',
    ServicesImei: 'Services imei',
    ServicesServer: 'Services server',
    AreaPersonal: 'Area personal',
    Profile: 'Profile',
    Bills: 'Bills',
    OrdersImei: 'Orders imei',
    OrdersServer: 'Orders server',
    InfoName: 'Require name',
    InfoLastName: 'Require last name',
}