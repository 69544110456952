import React from 'react';
import { useSelector } from 'react-redux';
import Cardfirst from '../../components/Home/Cardfirst';

const Homefirst = () => {

    const screen = useSelector((state) => state.general.general)

    return (
        <div>
            <div className='cenetr-items home-items' >
                <img className='width-100p' src={screen.homeimg.value} />
            </div>
            <Cardfirst />
        </div >
    )
}

export default Homefirst