export const es = {
    Login: 'Acceso',
    ExampleEmail: 'Usuario@ejemplo.com',
    ExamplePassword: 'Contraseña',
    Sigin: 'Iniciar sesion',
    Register: 'Crea tu nueva cuenta',
    FirstName: 'Nombre',
    LastName: 'Apellido',
    InfoEmail: 'Email requerido (puedes usar letras, numeros y puntos)',
    Address: 'Direccion',
    InfoAddress: 'La direccion es opcional',
    SelectCountry: '-Selecciona tu pais-',
    InfoCountry: 'El pais es opcional',
    PhoneNumber: 'Numero de telefono',
    InfoNumber: 'El numero de telefono es opcional',
    InfoPassword: 'Usa un minimo de 6 o mas caracteres',
    Confirm: 'Confirmar',
    ButtonRegister: 'Registrarse',
    ServicesFind: 'Encuentra servicios a traves de grupos:',
    AllGroups: 'Todos los grupos',
    Time: 'Tiempo',
    Price: 'Costo',
    AboutUs: 'Sobre nosotros',
    InfoAbout: 'Somos un grupo de trabajo, dedicado a los servicios de paginas automatizadas, tratando de cumplir con las expectativas de nuestros clientes y brindar un excelente servicio.',
    DeliveryTime: 'Tiempo de entrega',
    InfoDelivery: 'Los tiempos de entrega que ofrecemos son los más rápidos del mercado, entregados de manera rápida y eficiente.',
    Warranty: 'Garantia de servicios',
    InfoWarranty: 'Al contratar cualquiera de los servicios que ofrecemos, garantizamos un reembolso sólido siempre y cuando el servicio falle y se cumplan los términos de la garantía.',
    Security: 'Seguridad',
    InfoSecurity: 'Brindamos seguridad y protección al usuario, en cualquier interacción con nuestro sitio web, la información adquirida se almacena de forma segura y encriptada.',
    Menu: 'Menu',
    ConstactUs: 'Contactanos',
    Contacts: 'Contactos',
    TermsCondition: 'Terminos y Condiciones',
    PrivacyPolicy: 'Politica de Privacidad',
    YourEmail: 'Tu correo',
    YourMessage: 'Tu mensaje',
    Send: 'Enviar',
    Orders: 'Ordenes',
    YourBalance: 'Saldo:',
    AddFunds: '+Añadir fondos',
    Success: 'Exito',
    Rejects: 'Rechazadas',
    Reject: 'Rechazada',
    Proccess: 'Proceso',
    Waiting: 'En espera',
    News: 'Nuevas',
    Name: 'Nombre',
    NumAccount: 'Numero de cuenta',
    BasicInfo: 'Informacion basica',
    Country: 'Pais',
    Email: 'Correo electronico',
    PersonalInfo: 'Informacion personal',
    Update: 'Actualizar',
    Bills: 'Facturas',
    Amount: 'Monto',
    Reason: 'Razon',
    CreatedDay: 'Creado el dia',
    AllStatus: 'Todos los estados',
    SelectService: '--Selecciona El Servicio--',
    AllOrdersImei: 'Todos los pedidos imei',
    AllOrdersServer: 'Servidor de todos los pedidos',
    Service: 'Servicio',
    Imei: 'Imei',
    Response: 'Respuesta',
    Search: 'Buscar',
    SearchByImei: 'Buscar por imei',
    Reference: 'Referencia',
    Comments: 'Comentarios',
    Close: 'Cerrar',
    ResponsedAt: 'Respondio el',
    CreatedAt: 'Creado el',
    Data: 'Datos',
    Reason: 'Razon',
    OrdersHistory: 'Historial de Pedidos',
    StayPage: 'Permanecer en la pagina',
    TotalOrdersSend: 'Total de pedidos enviados',
    Language: 'Lenguaje',
    NewOrder: 'Nueva orden',
    AvailableBalance: 'Saldo disponible',
    Balance: 'Saldo',
    LogOut: 'Cerrar sesion',
    Home: 'Inicio',
    Register: 'Registrarse',
    Login: 'Iniciar sesion',
    ClientData: 'Datos del cliente',
    ServicesPrices: 'Servicios y precios',
    OrderHistory: 'Historial de pedidos',
    ServicesImei: 'Servicios imei',
    ServicesServer: 'Servidor de servicios',
    AreaPersonal: 'Area personal',
    Profile: 'Perfil',
    Bills: 'Facturas',
    OrdersImei: 'Pedidos imei',
    OrdersServer: 'Pedidos de servidor',
    InfoName: 'Nombre requerido',
    InfoLastName: 'Apellido requerido',
}