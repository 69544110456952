import React from 'react';
import { Route, Routes } from 'react-router-dom';
import IndexHome from '../views/auth/IndexHome';
import IndexService from '../views/auth/IndexServices';
import IndexServiceServer from '../views/auth/IndexServiceServer';
import LoginView from '../views/auth/LoginView';
import RegisterView from '../views/auth/RegisterView';

const PublicRoute = () => {
    return (
        <div className='con-tent min-height'>
            <Routes>
                {['', 'home'].map((path, k) => (
                    <Route key={k} path={path} element={<IndexHome />} />
                ))}
                <Route path='/services-imei' element={<IndexService />} />
                <Route path='/services-server' element={<IndexServiceServer />} />
                <Route path='/register' element={<RegisterView />} />
                <Route path='/login' element={<LoginView />} />
            </Routes>
        </div>
    )
}

export default PublicRoute