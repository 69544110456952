import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProfileUser from '../views/user/in-user/ProfileUser';
import DataUser from '../views/user/in-user/DataUser';
import BillsUser from '../views/user/in-user/BillsUser';
import ImeiServices from '../views/service/in-service/ImeiServices';
import HistoryOrders from '../views/orders/in-order/HistoryOrders';
import IndexUser from '../views/user/IndexUser';
import IndexServices from '../views/service/IndexServices';
import IndexOrders from '../views/orders/IndexOrders';
import ServerServices from '../views/service/in-service/ServerServices';
import HistoryOrdersServer from '../views/orders/in-order/HistoryOrdersServer';
import IndexHome from '../views/auth/IndexHome';
import Spinning from '../components/Spinning';

const PrivateRoute = () => {

    return (
        <div className='con-tent pages min-height'>
            <Spinning />
            <Routes>
                {['/', 'home'].map((path, k) => (
                    <Route key={k} path={path} element={<IndexHome />} />
                ))}
                <Route path='/' element={<IndexUser />} >
                    <Route path='client-data' element={<DataUser />} />
                    <Route path='/profile-info' element={<ProfileUser />} />
                    <Route path='/my-bills' element={<BillsUser />} />
                </Route>
                <Route path='/' element={<IndexServices />} >
                    <Route path='/imei-services' element={<ImeiServices />} />
                    <Route path='/server-services' element={<ServerServices />} />
                </Route>
                <Route path='/' element={<IndexOrders />} >
                    <Route path='/historial-imei' element={<HistoryOrders />} />
                    <Route path='/historial-server' element={<HistoryOrdersServer />} />
                </Route>
            </Routes>
        </div>
    )
}

export default PrivateRoute