import React, { useEffect } from 'react'
import { Routes } from '../Routes'
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';


const SideBar = (props) => {

    const { belong, className, handleClass, left, right } = props
    const style = document.documentElement.style;

    useEffect(() => {
        handleStyle()
    }, [])

    const handleStyle = () => {
        let element = document.getElementById('conten-list');
        if (!style.getPropertyValue('--Hg-Content')) {
            style.setProperty('--Hg-Content', `${element.clientHeight}px`);
        } else {
            style.setProperty('--Hg-Content', `${element.clientHeight}px`);
        }
    }

    return (
        <div className='wd-100p pst-fixed content-side'>
            {
                Routes().map((r, key) => {
                    return (
                        r.auth &&
                        <div key={key}>
                            <BiChevronLeft className='bttn-scrll' onClick={left} />
                            <ul id='scrll-list' className='list-side select-none list-none dp-flex'>
                                {
                                    r.items.map((t, k) => {
                                        return (
                                            t.belong === belong &&
                                            <div className='content-list' key={k} >
                                                {
                                                    t.action.map((a, index) => {
                                                        return (
                                                            <li
                                                                id='conten-list'
                                                                onClick={() => handleClass(a)}
                                                                className={`${className == a.className ? 'slct-opt' : ''} pd-10 cursor-pointer opt-side`}
                                                                key={index}
                                                            >
                                                                <i className='dp-flex' >{a.icon}</i>
                                                                <a className="pd-0_15 dg-nowrap" >{a.name}</a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </ul>
                            <BiChevronRight className='bttn-scrll1' onClick={right} />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SideBar