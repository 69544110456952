import React, { useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { RiContactsLine, RiChatHistoryLine } from 'react-icons/ri';
import { MdOutlinePayments, MdOutlineHistory } from 'react-icons/md';
import { AiOutlineBarcode } from 'react-icons/ai';
import { VscServerProcess } from 'react-icons/vsc';
import { useContext } from 'react';
import LanguageContext from './context/LanguageContext';

export const Routes = () => {
    const { texts } = useContext(LanguageContext);
    return (
        [
            {
                auth: false,
                items: [
                    {
                        name: texts.Home,
                        className: "",
                        path: '/',
                    },
                    {
                        name: texts.ServicesPrices,
                        // path: '/services',
                        className: "",
                        sub: [
                            {
                                name: texts.ServicesImei,
                                path: '/services-imei'
                            },
                            {
                                name: texts.ServicesServer,
                                path: '/services-server'
                            }
                        ]
                    },
                    {
                        name: texts.Register,
                        className: "",
                        path: '/register',
                    },
                    {
                        name: texts.Login,
                        className: "",
                        path: '/login',
                    }
                ]
            },
            {
                auth: true,
                items: [
                    {
                        name: texts.ClientData,
                        path: '/client-data',
                        className: "",
                        belong: 'data-client',
                        action: [
                            {
                                name: texts.AreaPersonal,
                                icon: <FaUserCircle />,
                                action: 'user',
                                path: "/client-data",
                                className: '/client-data',
                                scroll: -300
                            },
                            {
                                name: texts.Profile,
                                icon: <RiContactsLine />,
                                action: 'user',
                                path: "/profile-info",
                                className: '/profile-info',
                                scroll: 120
                            },
                            {
                                name: texts.Bills,
                                icon: <MdOutlinePayments />,
                                action: 'user',
                                path: "/my-bills",
                                className: '/my-bills',
                                scroll: 300
                            },
                        ]
                    },
                    {
                        name: texts.ServicesPrices,
                        path: '/imei-services',
                        className: "",
                        belong: 'imei-service',
                        action: [
                            {
                                name: texts.ServicesImei,
                                action: 'services',
                                icon: <AiOutlineBarcode />,
                                path: "/imei-services",
                                className: '/imei-services',
                                scroll: -300
                            },
                            {
                                name: texts.ServicesServer,
                                action: 'services',
                                icon: <VscServerProcess />,
                                path: "/server-services",
                                className: '/server-services',
                                scroll: 300
                            }
                        ]
                    },
                    {
                        name: texts.OrderHistory,
                        path: '/historial-imei',
                        className: "",
                        belong: 'order-history',
                        action: [
                            {
                                name: texts.OrdersImei,
                                action: 'order',
                                icon: <MdOutlineHistory />,
                                path: "/historial-imei",
                                className: '/historial-imei',
                                scroll: -300
                            },
                            {
                                name: texts.OrdersServer,
                                action: 'order',
                                icon: <RiChatHistoryLine />,
                                path: "/historial-server",
                                className: '/historial-server',
                                scroll: 300
                            },
                        ]
                    },
                    {
                        className: "log",
                    }
                ]
            }
        ]
    )
}
