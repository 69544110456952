import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import { get_general } from '../functions/GeneralFunction';
import { SetLogin } from '../store/storeReducers/AuthReducer';
import { auth_prelogin } from '../functions/AuthFunction';
import { useNavigate } from 'react-router-dom';
import { SetGeneral } from '../store/storeReducers/GeneralReducer';
import { useCookies } from 'react-cookie';

const AppRoute = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth.auth.loggedin);
    const [cookies, setCookie] = useCookies();

    useEffect(() => {
        onSubmit();
        getGeneral();
    }, []);

    const onSubmit = async () => {
        try {
            const res = await auth_prelogin();
            dispatch(
                SetLogin(res.data)
            );
        } catch (error) {
            // navigate('/home')
        }
    }

    const getGeneral = async () => {
        try {
            const res = await get_general();
            dispatch(SetGeneral(res.data.data));
        } catch (error) {
        }
    }

    return (
        <div className='full-page'>
            <NavBar
                auth={auth}
            />
            {
                auth ?
                    <PrivateRoute />
                    :
                    <PublicRoute />
            }
            <Footer />
        </div>
    )
}

export default AppRoute
