import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrdersServer } from '../../../store/storeReducers/OrdersReducer';
import { OrderStatus } from '../../../components/ImagenStatus';
import { get_orders } from '../../../functions/OrderFunction';
import Paginator from '../../../components/Paginator';
import Modalorders from '../../../components/modals/Modalorders';
import { useOutletContext } from 'react-router-dom';
import { update_Spin } from '../../../store/storeReducers/LoadingReducer';
import LanguageContext from '../../../context/LanguageContext';

const HistoryOrdersServer = () => {
    const { texts } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const param = useOutletContext();
    const orders = useSelector((state) => state.orders.orders.ordersServe);
    const spin = useSelector((state) => state.loading.loading.spin);
    const [load, setLoad] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [order, setOrder] = useState({});

    useEffect(() => {
        getOrders();
        dispatch(update_Spin(true));
    }, [param.count])

    const getOrders = async (value) => {
        setLoad(true);
        try {
            const order = {
                action: 'orders',
                type: 'SERVER',
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : '',
                per_page: value && value.per_page ? value.per_page : '',
                status: value && value.status ? value.status : '',
            };
            const res = await get_orders(order);
            dispatch(OrdersServer(res.data.orders));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Spin(false));
        setLoad(false);
    }

    const Status = [
        { name: texts.AllStatus, state: '' },
        { name: texts.Success, state: '4' },
        { name: texts.Rejects, state: '3' },
        { name: texts.Proccess, state: '1' },
        { name: texts.Waiting, state: '0' }
    ]

    const _open = (o) => {
        if (o) {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
            setOrder(o);
            setVisible(true);
        } else {
            setVisible(!isVisible);
            document.getElementsByTagName("body")[0].style.overflow = "auto";
        }
    }


    return (
        <div className='profile-content'>
            {
                !spin &&
                <div className='container'>
                    <div className='bg-white mg-left_auto mg-right_auto mg-bottom_100 mg-top_20  bd-radius_10 box-shadow_1 pd-10 '>
                        <Modalorders
                            isVisible={isVisible}
                            order={order}
                            onClose={() => _open()}
                        />
                        <div className='tx-center'>
                            <span className="ft-size_25">{texts.AllOrdersServer}</span>
                        </div>
                        <div className=" justify-content-center align-items-center table-responsive">
                            <div className="wd-100p ">
                                <table className='mg-left_auto mg-right_auto default_dg_table '>
                                    <thead >
                                        <tr >
                                            <th className=" tx-left default_dg_a_header ">
                                                <select
                                                    className="wd-100p bd-radius_10 outline-none ft-size_15 bd-solid_dadce0"
                                                    onChange={(e) => getOrders({ status: e.target.value })}
                                                >
                                                    {
                                                        Status.map((group, index) => {
                                                            return (
                                                                <option key={index} value={group.state}>{group.name}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </th>
                                            <th className=" bd-left_solid_f1 bd-right_solid_f1 tx-left default_dg_a_header">{texts.Service}</th>
                                            <th className=" bd-left_solid_f1 bd-right_solid_f1 tx-left default_dg_a_header">Qnt</th>
                                            <th className=" bd-left_solid_f1 bd-right_solid_f1 tx-left default_dg_a_header">{texts.Response}</th>
                                        </tr>
                                    </thead>
                                    {
                                        orders.data.map((o, k) => {
                                            const status = OrderStatus({ status: o.status, text: texts });
                                            return (
                                                <tbody key={k}>
                                                    <tr className="dg_tr ft-family_monospace">
                                                        <td onClick={() => _open(o)} className="cursor-pointer default_dg_td  tx-center dg_nowrap  white-space ">{status}</td>
                                                        <td className="default_dg_td tx-left dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space">{o.serviceimei.name}</td>
                                                        <td className="default_dg_td tx-left dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space">{o.qnt}</td>
                                                        <td className="inf default_dg_td tx-left dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space " dangerouslySetInnerHTML={{ __html: o.response }}></td>
                                                    </tr>
                                                </tbody>
                                            )
                                        })
                                    }
                                </table>
                                <Paginator
                                    data={orders}
                                    action={(o) => getOrders(o)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default HistoryOrdersServer