import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { api } from '../../api/ConnetApi';
import { SetLogin } from '../../store/storeReducers/AuthReducer';
import { useCookies } from 'react-cookie';
import LanguageContext from '../../context/LanguageContext';

const Loginview = () => {

    const { texts } = useContext(LanguageContext);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [load, setLoad] = useState(false);
    const [cookies, setCookie] = useCookies();
    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        }
        )
    }
    const login = async () => {
        setLoad(true);
        const v = {
            email: user.email,
            password: user.password
        }
        await axios.post(`${api}/login`, v, {
        }).then(res => {
            setCookie('auth_token', JSON.stringify(res.data), { path: '/' });
            // localStorage.setItem('save_auth', JSON.stringify(res.data));
            dispatch(SetLogin(res.data));
            navigate('/client-data');
        }).catch(err => {
            if (err.response.data.Err) {
                return alert(err.response.data.Err)
            }
            alert('Some of the data is wrong');
        })
        setLoad(false);
    }



    return (
        <div className='container pst-relative top-20' >
            <div className='bg-white mg-left_auto mg-right_auto default_dg_table mg-bottom_100 bd-radius_10 box-shadow_1 default_dg_td'>
                <div className="row justify-content-center align-items-center ">
                    <div className="col-12 col-lg-9 col-xl-7 cnt_itm">
                        <div className=' mg-bottom_20 mg-top_20'>
                            <span className="ft-size_25 ">{texts.Login}</span>
                        </div>
                        <form>
                            <div className="row">
                                <div className="col-md-12 mg-bottom_24">
                                    <div className="form-outline">
                                        <input
                                            autoComplete='new-password'
                                            onChange={(e) => handleChange(e)}
                                            name='email'
                                            className='wd-100p bd-solid_dadce0 outline-none height-36 bd-radius_4 pd-6_8'
                                            placeholder={texts.ExampleEmail} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mg-bottom_24">
                                    <div className="form-outline">
                                        <input
                                            onChange={(e) => handleChange(e)}
                                            name='password'
                                            autoComplete='new-password'
                                            type={'password'}
                                            className='wd-100p bd-solid_dadce0 outline-none height-36 bd-radius_4 pd-6_8'
                                            placeholder={texts.ExamplePassword} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <button
                            onClick={() => login()}
                            className='bd-solid_dadce0 cursor-pointer bg-primary color-white bd-radius_4 pd-5 mg-bottom_24'>
                            {
                                load ?
                                    <div className='spin login'></div>
                                    :
                                    <div>{texts.Sigin}</div>
                            }
                        </button>
                    </div>
                </div>

            </div></div>
    )
}

export default Loginview