import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './storeReducers/AuthReducer';
import GeneralReducer from './storeReducers/GeneralReducer';
import GroupsReducer from './storeReducers/GroupsReducer';
import LoadingReducer from './storeReducers/LoadingReducer';
import OrdersReducer from './storeReducers/OrdersReducer';
import TicketsReducer from './storeReducers/TicketsReducer';

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        groups: GroupsReducer,
        orders: OrdersReducer,
        tickets: TicketsReducer,
        loading: LoadingReducer,
        general: GeneralReducer
    }
})
