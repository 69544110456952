import React, { useContext, useEffect, useState } from 'react';
import { GroupImei } from '../../store/storeReducers/GroupsReducer';
import { useSelector, useDispatch } from "react-redux";
import ModalData from '../../components/modals/Modaldata';
import { get_groups_index } from '../../functions/GroupFunction';
import LanguageContext from '../../context/LanguageContext';

const Homeservices = () => {

    const { texts } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const allGroups = useSelector((state) => state.groups.groups.public.groupImei);
    const [groups, setGroups] = useState([]);
    const [isVisible, setVisible] = useState(false);
    const [serv, setServ] = useState({});

    useEffect(() => {
        getGroups();
    }, [])

    const getGroups = async (value) => {
        try {
            const group = {
                action: 'groups',
                type: 'IMEI',
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_groups_index(group);
            dispatch(GroupImei(res.data.groups));
            setGroups(res.data.groups);
        } catch (error) {
            alert(error.response.data);
        }
    }

    const _onChange = (e) => {
        if (e) {
            groups.map((g) => {
                if (e == g.id) {
                    dispatch(GroupImei([g]));
                }
            })
        } else {
            dispatch(GroupImei(groups));
        }

    }

    const _open = (s) => {
        if (s) {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
            setServ(s);
            setVisible(true);
        } else {
            setVisible(!isVisible);
            document.getElementsByTagName("body")[0].style.overflow = "auto";
        }

    }

    return (
        <div className='container' >
            <ModalData
                isVisible={isVisible}
                service={serv}
                onClose={() => _open()}
            />
            <div className='bg-white mg-left_auto mg-right_auto mg-top_20 mg-bottom_20 default_dg_table bd-radius_10 box-shadow_1 default_dg_td'>
                <div className="list-grp align-items-center">
                    <div className="col-md-4">
                        <div className="form-group">
                            <span >{texts.ServicesFind} </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <select
                                className="wd-100p bd-radius_10 pd-5 outline-none ft-size_15 bd-solid_dadce0"
                                onChange={(e) => _onChange(e.target.value)}
                            >
                                {
                                    [{ id: '', name: texts.AllGroups }, ...groups].map((group, index) => {
                                        return (
                                            <option key={index} value={group.id}>{group.name}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {
                allGroups.map((group, index) => {
                    return (
                        <table key={index} className="tb bg-white mg-left_auto mg-right_auto mg-bottom_20 mg-top_20 default_dg_table bd-radius_10 box-shadow_1">
                            <thead >
                                <tr >
                                    <th className="default_dg_td tx-left default_dg_a_header col-md-10">{group.name}</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">{texts.Time}</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">{texts.Price}</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    group.serviceimei.map((service, i) => {
                                        return (
                                            <tr key={i} onClick={() => _open(service)} className="dg_tr cursor-pointer ft-family_monospace">
                                                <td className="default_dg_td tx-left dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space">{service.name}</td>
                                                <td className="default_dg_td tx-center dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space">{service.time}</td>
                                                <td className="default_dg_td tx-center dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space">{(service.cost).toFixed(2)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    )
                })
            }
        </div>
    )
}

export default Homeservices