import React, { useContext } from 'react';
import moment from 'moment';
import LanguageContext from '../../context/LanguageContext';

const Modalorders = (props) => {
    const { texts } = useContext(LanguageContext);

    return (
        <>
            {
                props.isVisible == true &&
                <div className="container">
                    <div className="ebcf_modal ">
                        <div className='wd-600 ebcf_modal-content'>
                            <div className={`bg-${props.order.status} bd-corners_top_10 color-white justify-content-between align-items-center dp-flex tx-decoration_none ft-size_16 ft-weight_bold default_dg_a_header bd-bottom_solid_f1 mg-bottom_15`}>
                                <div className='pd-5'>
                                    {`${props.order.serviceimei.name} $${props.order.cost}`}
                                </div>
                                {
                                    props.order.imei &&
                                    <span className="ft-size_13 bg-f1f3f4 pd-5 bd-radius_5 color-black">
                                        {texts.Imei}: {`${props.order.imei}`}
                                    </span>
                                }
                                <span className='pd-5'>
                                    {texts.Reference}: {`${props.order.id}`}
                                </span>
                            </div>
                            <div className='dp-flex justify-content-between'>
                                <div className='pd-0_20'>
                                    <h3>{texts.Response}:</h3>
                                </div>
                                {
                                    props.order.response &&
                                    <div className="bd-dashed_050505 mg-10 mg-bottom_24 pd-30_20" dangerouslySetInnerHTML={{ __html: props.order.response }} ></div>
                                }
                            </div>
                            {
                                props.order.field_order &&
                                <table className='mg-left_auto mg-right_auto default_dg_table '>
                                    {
                                        [{ fieldname: texts.Comments, value: props.order.comments }, ...props.order.field_order].map((o, k) => {
                                            return (
                                                <tbody key={k}>
                                                    <tr className="dg_tr ft-family_monospace">
                                                        <td className="ft-weight_bold color-gray default_dg_td tx-center dg_nowrap white-space ">{o.fieldname}</td>
                                                        <td className="default_dg_td tx-left dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space">:</td>
                                                        <td className="inf default_dg_td tx-left dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space ">{o.value}</td>
                                                    </tr>
                                                </tbody>
                                            )
                                        })
                                    }
                                </table>
                            }
                            {/* {
                                props.order.comments &&
                                <div className='bd-bottom_solid_f1 pd-0_20 bd-radius_10'>
                                    <table className='mg-left_auto mg-right_auto default_dg_table '>
                                        <tbody >
                                            <tr className="dg_tr ft-family_monospace">
                                                <td className="default_dg_td  tx-center dg_nowrap  white-space ">Comments</td>
                                                <td className="default_dg_td tx-left dg_nowrap white-space">:</td>
                                                <td className="inf default_dg_td tx-left dg_nowrap bd-right_solid_f1 white-space " >{props.order.comments}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            } */}
                            <div className='mg-top_10 dp-flex justify-content-evenly mg-5 ft-size_11'>
                                <span className='bg-f1f3f4 bd-radius_5 pd-5 color-black'>
                                    {texts.CreatedAt} : {moment(props.order.created_at).format('DD-MM-YY HH:mm')}
                                </span>
                                <span className='bg-f1f3f4 bd-radius_5 pd-5 color-black'>
                                    {texts.ResponsedAt} : {moment(props.order.updated_at).format('DD-MM-YY HH:mm')}
                                </span>
                            </div>
                            <div className="bd-bottom_solid_f1 mg-top_20 mg-bottom_24">
                                <button className={`mg-left_15 cursor-pointer bd-solid_dadce0 bg-${props.order.status} color-white bd-radius_4 mg-bottom_24`} onClick={props.onClose}>
                                    {texts.Close}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Modalorders