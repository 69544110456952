import React, { useContext, useEffect, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Routes } from '../Routes';
import { SetOut } from '../store/storeReducers/AuthReducer';
import { sign_out } from '../functions/AuthFunction';
import Cookies from 'universal-cookie';
import LanguageContext from '../context/LanguageContext';

const NavBar = (props) => {
    const { texts } = useContext(LanguageContext);
    const cookies = new Cookies();
    const { auth } = props;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.auth.user);
    const general = useSelector((state) => state.general.general);
    const [dropDown, setDrop] = useState(false);
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();
    const style = document.documentElement.style;

    useEffect(() => {
        handleMobile();
    }, [])

    const handleStyle = (e) => {
        if (e && e.path) {
            style.setProperty('--Nav-subs', 'none');
            style.setProperty('--Open-nav', 'none');
            navigate(e.path);
            document.getElementsByTagName("body")[0].style.overflow = "auto";
            setDrop(false);
        }
        if (e && !e.path) {
            style.setProperty('--Nav-subs', 'inline');
        }
        if (style.getPropertyValue('--Hg-Navbar') !== '40px') {
            if (e && e.path) {
                style.setProperty('--Hg-Navbar', '40px');
            }
        }
    }

    const handleMobile = () => {
        if (style.getPropertyValue('--Hg-Navbar') === '40px') {
            style.setProperty('--Hg-Navbar', 'auto');
            style.setProperty('--Open-nav', 'inline-block');
        } else {
            style.setProperty('--Hg-Navbar', '40px');
            style.setProperty('--Open-nav', 'none');
            style.setProperty('--Nav-subs', 'none');
        }
    }

    const handleProfile = () => {
        setDrop(!dropDown);
    }

    const submitLogout = async () => {
        setLoad(true);
        try {
            const res = await sign_out();
            // localStorage.removeItem('save_auth');
            cookies.remove('auth_token');
            dispatch(SetOut(res.data))
            navigate('/home')
        } catch (error) {
        }
        setLoad(false);
    }

    return (
        <div className='nav-bar'>
            <div>
                <img onClick={() => navigate('/home')} className='cursor-pointer mg-left_16 max-hg_40 max-wd_40' src={general.logonav.value} />
            </div>
            <div className='nav'>
                <a onClick={() => handleMobile()}>
                    <i className='opn select-none'><GiHamburgerMenu /> </i>
                </a>
                {
                    Routes().map((r, key) => {
                        return (
                            r.auth === auth &&
                            <ul key={key} className='select-none list-none list-nav dp-flex'>
                                {
                                    r.items.map((t, k) => {
                                        return (
                                            <li className={`opt-nav`}
                                                key={k}
                                            >
                                                <a className='wd-100p column-direction dp-flex' key={k}  >
                                                    {
                                                        t.className &&
                                                        <>
                                                            <span onClick={() => handleProfile()} className={`${t.className}`} >
                                                                <span>{user.name}</span>
                                                                {dropDown ? <MdOutlineArrowDropUp /> : <MdOutlineArrowDropDown />}
                                                            </span>
                                                            {
                                                                dropDown === true &&
                                                                <div className='dropDown'>
                                                                    <span className='dp-block'>
                                                                        {texts.Balance}: ${user.balance}
                                                                    </span>
                                                                    {/* <span className='dp-block'>
                                                                        Api access
                                                                    </span> */}
                                                                    <button
                                                                        onClick={() => submitLogout()}
                                                                        className='tx-center_webkit bg-second bd-none cursor-pointer mg-top_20 color-white bd-radius_4 pd-5'>
                                                                        {
                                                                            load ?
                                                                                <div className='spin login wd-45'></div>
                                                                                :
                                                                                <div >{texts.LogOut}</div>
                                                                        }
                                                                    </button>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                    <span className={`nav-link${t.className}`} onClick={() => handleStyle({ path: t.path })}>{t.name}</span>
                                                </a>
                                                <div className='subs-nav'>
                                                    {
                                                        t.sub &&
                                                        t.sub.map((sub, index) => {
                                                            return (
                                                                <ul key={index} onClick={() => handleStyle({ path: sub.path, sub: undefined })} className='cursor-pointer sub-nav '>
                                                                    <li className='list-none'>
                                                                        <a>{sub.name}</a>
                                                                    </li>
                                                                </ul>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default NavBar